import React from 'react'

import { Styleable } from '../../types'
import { CloudinaryImage } from './types'
import LazyImage from '../../components/LazyImage'

interface ImageProps extends CloudinaryImage, Styleable {
  onClick?: () => void
  forcedWidth?: number
  forcedHeight?: number
  style?: React.CSSProperties
}

export function Image ({
  className, asset_id, public_id, width, height, onClick, forcedWidth, forcedHeight, style
}: ImageProps) {
  const w = forcedWidth || width
  const h = forcedHeight || height
  const url = `https://res.cloudinary.com/sokol-pisek/image/upload/c_fill,w_${w},h_${h},q_auto,f_auto/${public_id}`

  return (
    <LazyImage
      className={className}
      width={w}
      height={h}
      key={asset_id}
      src={url}
      onClick={onClick}
      alt=""
      wrapperStyle={style}
    />
  )
}
