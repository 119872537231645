import React from 'react'
import { Link } from 'gatsby'
import { motion, Variants } from 'framer-motion'

import { CloudinaryFolder, CloudinaryImage } from './types'
import { MQ } from '../../lib/constants'
import { useMedia } from '../../hooks'
import LazyImage from '../LazyImage'

import * as styles from '../../styles/gallery.module.sass'

interface FolderThumbProps extends CloudinaryFolder {
  thumbnail?: CloudinaryImage
  index: number
}

export function FolderThumb({ name, slug, thumbnail, index }: FolderThumbProps) {
  const size = useMedia([MQ.hd, MQ.wide, MQ.desktop, MQ.tablet], [400, 300, 200, 200], 400)

  const url = `https://res.cloudinary.com/sokol-pisek/image/upload/c_fill,w_${size},h_${size},q_auto,f_auto/${thumbnail?.public_id}`

  return (
    <motion.div
      className={styles.FolderThumb}
      variants={variants}
      initial="initial"
      animate="enter"
      transition={{ delay: index * 0.08 }}
      style={{ width: size, height: size }}
    >
      <Link to={slug}>
        {thumbnail &&
          <LazyImage
            {...thumbnail}
            width={size}
            height={size}
            src={url}
            alt={name}
          />
        }
        <h3>{name}</h3>
      </Link>
    </motion.div>
  )
}

const variants: Variants = {
  initial: {
    scale: 0.9,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
  }
}
