import React, { CSSProperties } from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


interface LazyImageProps extends HTMLMotionProps<'img'> {
  width: number
  height: number
  src: string
  alt: string
  aspectRatio?: CSSProperties['aspectRatio']
  wrapperStyle?: CSSProperties
}

function LazyImage({ className, width, height, src, aspectRatio, wrapperStyle, ...rest }: LazyImageProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px'
  })

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        aspectRatio,
        width,
        height,
        background: 'rgba(42, 75, 122, 0.25)',
        ...wrapperStyle
      }}
      className={className}
    >
      {inView ? (
        <motion.img
          {...rest}
          src={src}
          width={width}
          height={height}
          loading="lazy"
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      ) : null}
    </div>
  )
}

export default LazyImage
