import React, { useRef, useCallback, useState, useEffect } from 'react'
import { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'

import { MQ } from '../lib/constants'
import { useEventListener, useMedia } from '../hooks'
import Layout from '../components/layout'
import Lightbox2 from '../components/Lightbox2'
import {
  CloudinaryFolder,
  CloudinaryImage,
  FolderThumb,
  Image,
  Breadcrumbs,
} from '../components/gallery'

import * as styles from '../styles/gallery.module.sass'

const GAP = 20

interface Context {
  title: string
  slug: string
  prefix: string
  folderId: string
  folders: CloudinaryFolder[]
  images: CloudinaryImage[]
  childImages: CloudinaryImage[]
}

type GalleryTemplateProps = PageProps<unknown, Context>

function GalleryTemplate({
  pageContext: { folderId, folders, title, images, childImages },
  ...props
}: GalleryTemplateProps) {
  const gridRef = useRef<HTMLUListElement | null>(null)
  const [activeImage, setActiveImage] = useState<number | undefined>()
  const [maxWidth, setMaxWidth] = useState<number>(
    gridRef.current?.clientWidth ?? Infinity
  )
  const tileSize = useMedia(
    [MQ.hd, MQ.wide, MQ.desktop, MQ.tablet],
    [200, 150, 120, 90],
    90
  )
  const gap = useMedia([MQ.wide], [GAP], GAP / 2)

  const rootFolder = folders.find(({ parent }) => parent == null) ?? {
    path: '',
  }
  const childFolders = folders.filter(({ parent }) => parent?.id === folderId)
  const isRoot = props.path === `/${rootFolder.path}`

  const prev = () => {
    setActiveImage((old) => (old === 0 ? images.length - 1 : old! - 1)) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }
  const next = () => {
    setActiveImage((old) => (old === images.length - 1 ? 0 : old! + 1)) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  const handleClose = useCallback(() => {
    setActiveImage(undefined)
  }, [setActiveImage])

  useEventListener('resize', () => {
    setMaxWidth(gridRef.current?.clientWidth ?? Infinity)
  })

  useEffect(() => {
    setMaxWidth(gridRef.current?.clientWidth ?? Infinity)
  }, [])

  return (
    <Layout location={props.location} customClass="galerie">
      <Helmet
        htmlAttributes={{ lang: 'cs' }}
        title={`${
          !isRoot ? `${title} | ` : ''
        }Galerie | Sokol Písek - odbor všestrannosti`}
      />
      <header className={styles.Header}>
        <h1>{title}</h1>
      </header>
      {!isRoot && <Breadcrumbs activePath={props.path} allFolders={folders} />}
      <ul className={styles.Content}>
        {childFolders.map((folder, i) => (
          <FolderThumb
            key={folder.id}
            thumbnail={
              childImages.find(
                ({ public_id, tags }) =>
                  public_id.split('/').slice(0, -1).join('/') ===
                    folder.prefix && tags.includes('cover')
              ) ??
              childImages.find(
                ({ public_id, tags }) =>
                  public_id.startsWith(folder.prefix) && tags.includes('cover')
              ) ??
              childImages.find(({ public_id }) =>
                public_id.startsWith(folder.prefix)
              )
            }
            index={i}
            {...folder}
          />
        ))}
      </ul>
      <ul
        className={styles.Grid}
        ref={gridRef}
        style={{
          gridAutoRows: tileSize,
          gridTemplateColumns: `repeat(auto-fit, ${tileSize}px)`,
          gap,
        }}
      >
        {!(images.length === 1 && images[0].tags.includes('cover')) &&
          images.map((image, i) => {
            const isPortrait = image.height > image.width
            const normalizedHeight = isPortrait
              ? (1 / image.width) * image.height
              : 1
            const normalizedWidth = isPortrait
              ? 1
              : (1 / image.height) * image.width

            const widthSpan =
              Math.floor(normalizedWidth) * tileSize < maxWidth
                ? Math.floor(normalizedWidth)
                : Math.floor((maxWidth + gap) / (tileSize + gap))

            const heightSpan = Math.floor(normalizedHeight)

            const size = {
              width: widthSpan * tileSize + (widthSpan - 1) * gap,
              height: heightSpan * tileSize + (heightSpan - 1) * gap,
            }

            return (
              <Image
                key={image.asset_id}
                className={styles.Image}
                onClick={() => setActiveImage(i)}
                forcedWidth={size.width}
                forcedHeight={size.height}
                {...image}
                style={{
                  gridColumnEnd: `span ${widthSpan}`,
                  gridRowEnd: `span ${heightSpan}`,
                }}
              />
            )
          })}
      </ul>
      {activeImage != null && (
        <Lightbox2
          image={images[activeImage]?.url}
          close={handleClose}
          prev={prev}
          next={next}
        />
      )}
    </Layout>
  )
}

export default GalleryTemplate
