import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import { motion, Variants } from 'framer-motion'

import { CloudinaryFolder } from './types'
import { useLayoutContext } from '../../contexts/LayoutContext'

import * as styles from '../../styles/gallery.module.sass'
import { useIsMobile } from '../../hooks'

interface BreadcrumbsProps {
  allFolders: CloudinaryFolder[]
  activePath: string
  root?: boolean
}

export function Breadcrumbs ({ allFolders, activePath }: BreadcrumbsProps) {
  const [sticky, setSticky] = useState(false)
  const { mainElement } = useLayoutContext()

  const handleEnter = useCallback(() => { setSticky(false) }, [setSticky])
  const handleLeave = useCallback(() => { setSticky(true) }, [setSticky])

  const boundingRect = useMemo(() => {
    if (mainElement) {
      const { top, left } = mainElement.getBoundingClientRect()
      const { paddingLeft } = getComputedStyle(mainElement)
      return { top, left, paddingLeft }
    } else {
      return { top: 0, left: 0, paddingLeft: 0 }
    }
  }, [mainElement])

  const elements = activePath.split('/').filter(Boolean)

  const items = elements.map((_, i, arr) =>
    allFolders.find(folder =>
      folder.path === arr.slice(0, i + 1).join('/')
    )
  )

  return <>
    <motion.nav
      className={styles.Breadcrumbs}
      whileInView={{ display: 'relative' }}
      onViewportEnter={handleEnter}
      onViewportLeave={handleLeave}
      viewport={{ once: false }}
    >
      <motion.ul
        variants={variants}
        animate={sticky ? 'sticky' : 'default'}
        custom={boundingRect}
        style={{ paddingRight: useIsMobile() && sticky ? 100 : undefined }}
      >
        {items.map((item, i) => item && (
          <li key={item.id}>
            {i !== items.length - 1 ? (
              <Link to={`/${item.path}`}>{item.name}</Link>
            ) : (
              item.name
            )}
          </li>
        ))}
      </motion.ul>
    </motion.nav>
  </>
}

const variants: Variants = {
  default: {
    display: 'block',
    position: 'static',
    backgroundColor: 'rgba(0, 60, 120, 0.0)',
    backdropFilter: 'none',
    y: '0%',
    top: 0,
    left: 0,
    right: 0,
    paddingLeft: '0px',
    transition: {
      paddingLeft: {
        duration: 0
      },
      backgroundColor: {
        duration: 0
      }
    }
  },
  sticky: ({ top, left, paddingLeft }) => ({
    position: 'fixed',
    zIndex: 1,
    top,
    left,
    right: 0,
    paddingLeft,
    backgroundColor: 'rgba(0, 60, 120, 0.75)',
    backdropFilter: 'blur(5px)',
    y: ['-100%', '0%'],
  })
}
