import React from 'react'

import { Styleable } from '../types'
import { classes } from '../utils'

const Throbber = ({ className }: Styleable) => (
  <span className={classes('Throbber', className)}>
    <span></span>
    <span></span>
  </span>
)

export default Throbber
