import React, { useEffect, useCallback, MouseEventHandler, useState } from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'

import { Chevron } from './icons'
import Throbber from './throbber'

import * as styles from '../styles/lightbox.module.sass'

const backdropVariants: Variants = {
  open: {
    background: 'rgba(0, 0, 0, 0.8)',
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.15,
      duration: 0.3,
      when: 'beforeChildren'
    }
  },
  close: {
    background: 'rgba(0, 0, 0, 0)',
    transition: {
      duration: 0.2,
      delayChildren: 0.2,
      when: 'afterChildren'
    }
  }
}

const imageVariants: Variants = {
  open: {
    opacity: 1,
    scale: 1,
  },
  close: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  loading: {
    opacity: 0.1,
    scale: 0.5,
  }
}

const arrowVariants: Variants = {
  open: {
    x: 0,
    opacity: 1,
  },
  close: (left?: boolean) => ({
    x: left ? '-20%' : '20%',
    opacity: 0,
  })
}

interface LightboxProps {
  image?: string
  close: () => void
  open?: boolean
  prev?: () => void
  next?: () => void
}

function Lightbox2 ({ image, close, prev, next }: LightboxProps) {
  const [loading, setLoading] = useState(false)

  const handlePrev = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation()
    if (prev) {
      prev()
    }
  }, [prev])

  const handleNext = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation()
    if (next) {
      next()
    }
  }, [next])

  const handleClose = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation()
    close()
  }, [close])

  const onKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') close()
    if (prev && event.key === 'ArrowLeft') prev()
    if (next && event.key === 'ArrowRight') next()
  }, [close, prev, next])

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress)
    return () => window.removeEventListener('keydown', onKeyPress)
  }, [onKeyPress])

  useEffect(() => {
    if (image) {
      setLoading(true)
    }
  }, [image])

  return (
    <motion.div
      className={styles.Lightbox}
      variants={backdropVariants}
      initial="close"
      animate="open"
      onClick={handleClose}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.img
          key={image}
          src={image}
          variants={imageVariants}
          initial="close"
          animate={loading ? 'loading' : 'open'}
          exit="close"
          onLoad={() => setLoading(false)}
        />
      </AnimatePresence>
      {loading && <Throbber className={styles.Throbber} />}
      {prev &&
        <motion.div
          className={styles.arrowLeft}
          onClick={handlePrev}
          variants={arrowVariants}
          custom={true}
        >
          <Chevron />
        </motion.div>
      }
      {next &&
        <motion.div
          className={styles.arrowRight}
          onClick={handleNext}
          variants={arrowVariants}
        >
          <Chevron right />
        </motion.div>
      }
    </motion.div>
  )
}

export default Lightbox2
